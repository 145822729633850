import Container from "@src/components/Container";
import { Column, Image } from "@src/components/extended";
import React from "react";
import Mojo from "./assets/Mojo.png";
import ReactMarkdown from "react-markdown";
import SEO from "@src/components/SEO";

const TEXT = `
NO PURCHASE OR PAYMENT OF ANY MONEY IS NECESSARY TO ENTER OR WIN.  A PURCHASE WILL NOT IMPROVE THE CHANCES OF WINNING. THIS IS A CONTEST OF SKILL. VOID WHERE PROHIBITED or restricted BY LAW OR WHERE REGISTRATION REQUIRED. THIS CONTEST IS SUBJECT TO ALL FEDERAL, STATE AND LOCAL LAWS.

1. SPONSOR: ClassDojo, Inc., located at 735 Tehama Street, San Francisco, California 94103 (“Sponsor”).

2. AGREEMENT TO OFFICIAL RULES: By participating in the Contest (as defined below), you, on behalf of yourself and the student creator of the entry, expressly agree to (i) be bound by these official rules (“Official Rules”) and the decisions of the Sponsor, including without limitation decisions regarding eligibility, winners, and the interpretation of terms used in these Official Rules, which are final and binding in all aspects, and (ii) receive marketing communications regarding ClassDojo products, services, and events.  By entering, you represent that you have the right to enter and agree to the Official Rules on behalf of the student creator of the entry and that entering would not be prohibited by your school or employer..

3. ENTRY PERIOD: The 2025 Monster of the Year Contest (“Contest”) sponsored by Sponsor begins on January 6th, 2025 at 9am PST and ends on February 14th, 2025, at 12pm PST (“Entry Period”). All times in these Official Rules are Pacific Time Zone. Sponsor’s computer will be the official clock for the Contest. All entries must be received (as defined below) during the Entry Period to be eligible to enter the Contest or win a prize.

4. ELIGIBILITY: The Contest is open to individuals (“Entrant” or “you”) that live in the United States who are 18 years of age or older or who have reached the age of majority in their jurisdiction of residence as of the date of entry and (i) teachers at an accredited educational institution (a “Participating Teacher”); or (ii) are a parent or legal guardian of a student of a Participating Teacher). By entering, you are entering on behalf of yourself and the student creator of the Entry and you represent that you have all rights to do so and you acknowledge that you and the student creator are bound to these Official Rules.  Both the person submitting the entry and the student creator of the entry will be an Entrant.  Sponsor reserves the right to have parents or legal guardians sign additional entry documents binding themselves and their children to these Official Rules, including at the finalist stage.  Employees, contractors, consultants, officers, directors, and agents (and their immediate families and household members regardless of where they live), or members of the same households, whether related or not, of Sponsor, its parents, divisions, subsidiaries, agents, promotional partners, advertising/promotion agencies, and prize suppliers and deliverers (“Contest Entities”) are not eligible to win any prize. Failure to meet the eligibility requirements will result in an entry being invalid and forfeiture of any prize. Sponsor reserves the right to disqualify any Entrant that Sponsor determines could potentially reflect negatively on Sponsor’s image. Sponsor’s decisions regarding eligibility and all other aspects of the Contest are final and binding subject to applicable laws.

5. PRIVACY: Sponsor collects personal information from you when you enter the Contest. The information collected is subject to Sponsor’s privacy policy (as may be amended from time to time), which can be found at https://www.classdojo.com/privacy/ (“Privacy Policy”). In the event of any conflict between these Official Rules and Sponsor’s Privacy Policy, the terms and conditions of these Official Rules shall prevail. The personal information of participants and winners may be provided to corporate affiliates of the Sponsor, third parties assisting in the conduct of the Contest, including with Contest Entities, and for publicity purposes surrounding the Contest. If the personal information requested is not provided, you may not participate in the Contest. By participating in the Contest, you hereby agree to Sponsor’s collection and usage of your personal information as described and in accordance with the Privacy Policy and acknowledge that you have read and accepted the Privacy Policy.

6. HOW TO ENTER:  To enter the Contest, you must either (i) go to the Contest page at classdojo.com/monster-of-the-year-2025 and upload your entry; (ii) send the entry via email to submissions@classdojo.com; OR (iii) send the entry via mail to 735 Tehama Street, San Francisco, California 94103   (“2025 Monster of the Year Contest Submission”). Each 2025 Monster of the Year Contest Submission must include the name of the Entrant, email address and the name of the student creator. If you have questions about how to enter, please reach out to submissions@classdojo.com.
In the event of a dispute as to the identity of Entrants or winners, Sponsor shall make the final determination as to identity. You agree that, as between you and Sponsor, Sponsor has the sole right to decide all matters and disputes arising from the Contest and that all decisions of Sponsor related to the Contest are final and binding. Sponsor reserves the right to disqualify and prosecute to the fullest extent permitted by law any Entrant or winner who it suspects has tampered with the Contest, the entry or participation process, violated the Official Rules, or acted in an unsportsmanlike or disruptive manner.

7. PRIZE DETAILS: The prizes described below will be awarded after completion of the Entry Period and after determination of winners by Sponsor (“Prizes”). No substitution, assignment or transfer of Prizes is permitted, except by Sponsor, who has the right to substitute Prizes with others of comparable or greater value.  Sponsor is not responsible for any errors or delays in the awarding of Prizes.
Grand Prize:
   * $3,000 Scholarship for the Winning Student (Child)
   * $2,000 for the School
   * $1,000 for the Winning Student's Teacher
   * School Visit from Mojo with a school-wide pizza party
   * Artwork made in 3D, presented as a trophy
   * Character playable in Dojo Islands & brought to life in the ClassDojo universe
Grade-Level Winners:
   * ClassDojo swag for the Winning Student’s class & teacher
   * $1,000 scholarship for the Winning Student
   * Framed poster & certificate for the school
Approximate retail value (“ARV”)
The total ARV of all prizes is ~$20,000.

8. PRIZE CONDITIONS: Sponsor is not responsible for Prizes that are unable to be awarded. Winners may be responsible for any costs and expenses not listed herein. Prizes may be reported as income to a winner and all federal, state/provincial/territorial, and local taxes, fees and surcharges and taxes (whether foreign or domestic, and including income, sales, and import taxes) on Prizes are the sole responsibility of the Prize winners. Please consult a tax professional to discuss your particular situation. Winners may be required to complete required tax forms or other documents requested within the time period stipulated by Sponsor. Sponsor reserves the right to amend or cancel the Contest for any reason, including if the fairness of the Contest is compromised. Proof of identity, employment at a K-12 educational institution, age and/or legal residence may be required to claim a Prize. Failure to comply with any of the requirements set forth in the Official Rules may result in disqualification or forfeiture of a Prize. There is a limit of one (1) prize per Entrant.

9. WINNER SELECTION AND ANNOUNCEMENT:  There will be 1 grand prize winner selected & 8 grade-level winners (Kindergarten, 1st Grade, 2nd Grade, 3rd Grade, 4th Grade, 5th Grade, Middle School which is inclusive of 6th-8th Grade, and High School which is inclusive of 9th - 12th Grade). Grade-level finalists & the grand prize winner is determined by Sponsor or its designees. Public voting at the Contest website www.classdojo.com/monster- of-the-year-2025 will determine the grade-level winners. Sponsor reserves the right to select (according to the Judging Criteria below) a subset of entries to be considered for grade-level voting.  The entries will be judged separately, in accordance with the following equally weighted criteria (“Judging Criteria”): (1) quality of the Entry as a potential ClassDojo character; and (2) creativity. Odds of winning depend on the number of people participating and the skill of the entrants. Sponsor will select the indicated number of winners from among all eligible entries received during the Entry Period. Winners will be announced at classdojo.com/monster-of-the-year-2025.
Each winner is deemed to be a potential winner pending verification, including without limitation, verification of eligibility, compliance with the terms and conditions of the Contest, execution of an affidavit of eligibility, publicity release and license grant, and completion of any required documents as determined by Sponsor, at Sponsor’s sole discretion. Sponsor will not be liable for any potential Prize notification that is lost, intercepted, or not received by any potential winner for any reason. If a potential winner cannot be contacted within five (5) days after the first attempt to contact the winner, the Sponsor may select an alternate potential winner from the remaining eligible entries.

10. PUBLICITY RELEASE AND ADDITIONAL AGREEMENTS: Each winner may be required to sign an affidavit of eligibility, liability release, and a publicity release that, among other things, will allow Sponsor to use the winner’s name, photograph, likeness, social media posts (including video or photo), voice, prize information, opinions, and biographical information for publicity and promotional purposes without further compensation where permitted by law, including in connection with promotion winner on Sponsor’s website. Except where prohibited, participation in the Contest constitutes your consent to Sponsor’s use of your name, photograph, likeness, social media post (including video or photo), voice, prize information, opinions, and biographical information, for promotional purposes in any media without further payment or consideration.

11. LICENSE TO ENTRY:  By submitting an Entry, you promise that  the Entry is original and that you have not copied anyone else and does not break any applicable law or regulation. By entering the Contest, all entrants hereby grant to Sponsor, Contest Entities, and any persons or entities authorized by Sponsor or Contest Entities (together, “Authorized Parties”) an irrevocable, perpetual, worldwide non-exclusive, sublicensable, royalty-free license to use, reproduce, modify, distribute, publicly display and perform, transmit, broadcast, and create derivative works of the entries or otherwise publish throughout the world for any purpose whatsoever, including in connection with the Contest and promotion of the Contest, in any format or media now or hereafter known, for no additional compensation including but not limited to display at a potential exhibition of winners; publication on the ClassDojo website; in social media; publication in the ClassDojo services, including in logged in areas of the ClassDojo website and downloadable application; and offering as downloadable videos to users of the ClassDojo service (the “License”). Entrants consent to Authorized Parties doing or omitting to do any act that would otherwise infringe the entrant’s “moral rights” in their entries. Entrant hereby waives any and all rights or privacy, publicity or other rights of a similar nature in and connection with the entries or any portion thereof.
The rights set forth in these Official Rules Release are granted as a condition of, and as consideration for, Sponsor permitting me to participate in the Contest and/or other consideration, the sufficiency of which is hereby acknowledged. Entrant grants these rights without requiring other compensation, remuneration or approval of Authorized Parties.  Display or publication of any entry on an Authorized Party’s website does not indicate the Entrant will be selected as a winner.  Entrant understands that Sponsor is under no obligation to use, distribute or exhibit the entry, and waives any right that Entrant may have to prior inspection or approval of the entry.

12.  Upon Sponsor's request, each entrant must be prepared to provide (within seven (7) calendar days of receipt of Sponsor's request) a signed release from all persons who appear in the entry submitted, and/or from the owner of any material that appears in the photograph entry, authorizing Authorized Parties to reproduce, distribute, display, and create derivative works of the entry in connection with the Contest and promotion of the Contest, in any media now or hereafter known. Failure to provide such releases upon request may result in disqualification at any time during the Contest and selection of an alternate winner.
Similarly, upon Sponsor's request, each entrant must be prepared to provide (within seven (7) calendar days of receipt of Sponsor's request) a signed written license from the copyright owner of any sculpture, artwork, or other copyrighted material that appears in the entry, authorizing any Authorized Party to reproduce, distribute, display, and create derivative works of the entry in connection with the Contest and promotion of the Contest, in any media now or hereafter known. Failure to provide such releases upon request may result in disqualification and selection of an alternate winner.

For the purposes of these Official Rules, the Entrant will be deemed to be in receipt of Sponsor's request or notification, (a) in the event that Sponsor sends the request by postal mail, five business days after the request was sent by Sponsor, or (b) in the event that Sponsor sends the request by email, on the day that the email was sent by Sponsor.

13. REPRESENTATION AND WARRANTIES AND CONDUCT:  BY ENTERING AND PARTICIPATING IN THIS CONTEST, YOU: (A) REPRESENT AND WARRANT THAT ALL INFORMATION PROVIDED BY YOU IN CONNECTION WITH THE CONTEST IS TRUE, ACCURATE, AND COMPLETE; (B) HAVE FULL AUTHORITY TO GRANT THE RIGHTS SET FORTH IN THESE OFFICIAL RULES; AND (C) AGREE TO COMPLY WITH ALL FEDERAL, STATE, AND LOCAL LAWS AND REGULATIONS.

Any attempt by Entrant or any other individual to deliberately damage any internet site or undermine the legitimate operation of the Contest is a violation of these Official Rules  and may violate criminal and civil laws. Should Sponsor believe or become aware that such an attempt has been, is being, or will be made, Sponsor reserves the right to seek remedies and damages from any responsible Entrant(s) and other responsible individual(s) in the attempted damage to the fullest extent permitted by law, including without limitation criminal prosecution.

14. WARRANTY DISCLAIMER: EACH PRIZE IS PROVIDED (OR WILL BE AWARDED) "AS IS" AND WITHOUT WARRANTY OF ANY KIND, AND SPONSOR AND CONTEST ENTITIES HEREBY DISCLAIM ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED (INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT), CONCERNING ANY PRIZE FURNISHED OR MADE AVAILABLE IN CONNECTION WITH THE CONTEST.

15. GENERAL RELEASE: YOU HEREBY HOLD SPONSOR, CONTEST ENTITIES, AUTHORIZED PARTIES, THEIR AFFILIATES, AGENTS, DIRECTORS, OFFICERS, EMPLOYEES, AND SHAREHOLDERS (COLLECTIVELY “RELEASEES”) HARMLESS FROM AND AGAINST ANY AND ALL LOSSES, DAMAGES, RIGHTS, CLAIMS, AND ACTIONS OF ANY KIND ARISING IN WHOLE OR IN PART, DIRECTLY OR INDIRECTLY, FROM THE CONTEST, PARTICIPATION IN ANY CONTEST RELATED ACTIVITY, OR RESULTING DIRECTLY OR INDIRECTLY FROM THE ACCEPTANCE, POSSESSION, USE OR MISUSE OF ANY PRIZE AWARDED IN CONNECTION WITH THE CONTEST, INCLUDING WITHOUT LIMITATION PERSONAL INJURY, DEATH AND/OR PROPERTY DAMAGE, AS WELL AS CLAIMS BASED ON PUBLICITY RIGHTS, DEFAMATION AND/OR INVASION OF PRIVACY AND THE COLLECTION, USE AND/OR SHARING BY SPONSOR AND CONTEST ENTITIES OF PERSONALLY IDENTIFIABLE INFORMATION OF ENTRANTS, OR FOR ANY PRINTING, PRODUCTION, TYPOGRAPHICAL OR HUMAN ERROR.
RELEASEES ASSUME NO RESPONSIBILITY FOR ANY ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, OR DELAY IN OPERATION OR TRANSMISSION OR ALTERATION OF ENTRIES. RELEASEES ARE NOT RESPONSIBLE FOR ANY PROBLEMS WITH OR TECHNICAL MALFUNCTION OF ANY TELEPHONE NETWORK OR LINES, COMPUTER ONLINE SYSTEMS, SERVERS OR PROVIDERS, COMPUTER EQUIPMENT, SOFTWARE, FAILURE OF ANY ENTRY TO BE RECEIVED ON ACCOUNT OF TECHNICAL PROBLEMS OR TRAFFIC CONGESTION ON THE INTERNET OR AT ANY WEBSITE, HUMAN ERRORS OF ANY KIND, OR ANY COMBINATION THEREOF, INCLUDING ANY INJURY OR DAMAGE TO ENTRANTS' OR ANY OTHER PERSONS' COMPUTERS, WIRELESS DEVICES OR OTHER EQUIPMENT RELATED TO OR RESULTING FROM PARTICIPATION, UPLOADING OR DOWNLOADING OF ANY MATERIALS RELATED TO THE CONTEST, OR IF FOR ANY REASON THE CONTEST IS NOT CAPABLE OF BEING CONDUCTED AS PLANNED, INCLUDING BUT NOT LIMITED TO INFECTION BY COMPUTER VIRUSES, BUGS, TAMPERING, UNAUTHORIZED INTERVENTION, FRAUD, TECHNICAL FAILURES OR OTHER CAUSES BEYOND THE CONTROL OF RELEASEES.

16. LIMITATION OF LIABILITY: IN NO EVENT WILL RELEASEES BE RESPONSIBLE OR LIABLE FOR ANY DAMAGES OR LOSSES OF ANY KIND, INCLUDING WITHOUT LIMITATION, ATTORNEYS FEES, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES, ARISING OUT OF OR RELATED TO ANY ACCESS TO AND/OR USE OF ANY WEBSITE, THE DOWNLOADING FROM AND/OR PRINTING MATERIAL DOWNLOADED FROM ANY WEBSITE, THE REMOVAL FROM ANY WEBSITE OF, OR DISCONTINUATION OF ACCESS TO, ANY MATERIALS, OR ANY HARM RESULTING FROM THE ACCEPTANCE, POSSESSION, USE OR MISUSE OF, OR ATTENDANCE OR PARTICIPATION IN, ANY PRIZE AWARDED IN CONNECTION WITH THE CONTEST.  THE RELEASEES’ LIABILITY WILL BE LIMITED TO THE COST OF ENTERING AND PARTICIPATING IN THE CONTEST.

17. DISQUALIFICATION/FORCE MAJEURE: Sponsor reserves the right, at any time and in its sole discretion, to disqualify and/or deem ineligible to participate in this Contest, any individual who Sponsor believes to be: (A) tampering with the entry process or the operation of the Contest or any website, (B) acting in violation of these Official Rules or Sponsor’s Privacy Policy or other terms, conditions or guidelines; (C) acting in bad faith, an unsportsmanlike or disruptive manner; (C) disrupting or undermining the legitimate operation of the Contest; or (D) annoying, abusing, threatening or harassing any other person. Sponsor reserves the right to seek damages and other remedies from any such person to the fullest extent permitted by law.

In the event an insufficient number of eligible entries are received and/or Sponsor is prevented from awarding prizes or continuing the Contest as contemplated in these Official Rules by any event beyond its control (“Force Majeure Event”), including but not limited to fire, flood, natural or man-made epidemic of health of other means, earthquake, explosion, labor dispute or strike, act of God or public enemy, satellite or equipment failure, riot or civil disturbance, terrorist threat or activity, war (declared or undeclared) or any federal state or local government law, order, or regulation, public health crisis, order of any court or jurisdiction, then Sponsor will have the right to modify, suspend, or terminate the Contest. If the Contest is terminated before the designated end date, Sponsor will (if possible) select the winners from all eligible, non-suspect entries received as of the date of the Force Majeure Event giving rise to the termination. Inclusion in such selection process will be each Entrant’s sole and exclusive remedy under such circumstances. Only the type and quantity of Prizes described in these Official Rules will be awarded.

18. GENERAL CONDITIONS: Sponsor reserves the right to shorten, extend, modify, or cancel the Contest, in its sole discretion, at any time and without notice, even though such action may affect your ability to win a prize. Sponsor is not responsible for any typographical or other error in printing or description of the Contest. Entries are void if unreadable, inaccurate, and incomplete, mutilated, tampered with, forged, mechanically reproduced, irregular in any way or otherwise not in compliance with these Official Rules. Although Sponsor attempts to ensure the integrity of the Contest, Sponsor and Contest Entities are not responsible for the actions of Entrants or other individuals in connection with the Contest, including Entrants’ or other individuals’ attempts to circumvent the Official Rules or otherwise interfere with the administration, security, fairness, integrity or proper conduct of the Contest. In the event of any conflict between any details contained in these Official Rules and details contained in any Contest advertising materials, the details of the Contest as set forth in these Official Rules shall prevail.

19. SEVERABILITY: If any provision of these Official Rules is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed severable from these Official Rules and shall not affect the validity and enforceability of any remaining provisions. Headings and captions are used in these Official Rules solely for convenience of reference and shall not be deeded to affect in any manner the meaning or intent of these Official Rules or any provision thereof.

20. DISPUTES: Except where prohibited, all disputes relating to the Contest will be exclusively resolved under confidential binding arbitration held in San Francisco, California. All disputes will be resolved in accordance with the Rules of JAMS, applying California law, without regard to conflicts of law principles. You and Sponsor agree to submit to the personal and exclusive jurisdiction of the federal and California state courts in San Francisco County for purposes of enforcing any arbitration award. Notwithstanding the foregoing, Sponsor may seek injunctive or other equitable relief, from a court of competent jurisdiction. You and Sponsor agree that any dispute resolution proceedings will be conducted only on an individual basis and not in a class, consolidated or representative action. You and Sponsor agree that any cause of action arising out of or related to the Contest must commence within one (1) year after the cause of action arose; otherwise, such cause of action is permanently barred.

21. CONTEST RESULTS: To request a list of winners’ names or a copy of the Official Rules, send a self-addressed, stamped envelope to ClassDojo, Inc. Attn: Monster of the Year Submissions, ClassDojo HQ, 735 Tehama Street, San Francisco, California 94103, or send an email to submissions@classdojo.com. Requests must be received within end of the Entry Period. Vermont residents may omit postage.
`;

const RulesPage = () => {
  return (
    <>
      <SEO title="ClassDojo Monster of the Year 2025 - Rules" />
      <Container maxWidth={1000}>
        <Column mb={100}>
          <Image img={Mojo} alt="Mojo" height={450} />
          <ReactMarkdown>{TEXT}</ReactMarkdown>
        </Column>
      </Container>
    </>
  );
};

export default RulesPage;
